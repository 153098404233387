import { ERROR_MESSAGE } from 'core/constants/common';
import { STATUS_CODE } from 'core/constants/numbers';
import {
    LOGIN,
    VERIFY_USER,
    CREATE_USER,
    NAME,
    RESEND_INVITE,
    VALIDATE_EMAIL_UPDATE,
    INVITE_USER,
    RESET_PASSWORD,
    BUMP_FILTER,
    BOOMP,
    BOOST,
    SUBMIT_OFFER
} from './uiMessages';

interface Props {
    errorCode: number;
    name?: string;
}

const getCommonMessage = (errorCode: number) => {
    switch (errorCode) {
        case STATUS_CODE.UNAUTHORIZED:
            return ERROR_MESSAGE.UNAUTHORIZED;
        case STATUS_CODE.TOO_MANY_REQUESTS:
            return ERROR_MESSAGE.TOO_MANY_REQUESTS;
        default:
            return ERROR_MESSAGE.COMMON;
    }
};

const getLoginMessage = (errorCode: number) => {
    switch (errorCode) {
        case STATUS_CODE.UNAUTHORIZED:
        case STATUS_CODE.FORBIDDEN:
            return LOGIN.BAD_CREDENTIALS;
        case STATUS_CODE.TOO_MANY_REQUESTS:
            return ERROR_MESSAGE.TOO_MANY_REQUESTS;
        default:
            return LOGIN.FAILED;
    }
};

const getCreateUserMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.CONFLICT ? CREATE_USER.CONFLICT : getCommonMessage(errorCode);

const getResendInviteMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.NOT_FOUND ? RESEND_INVITE.NOT_FOUND : getCommonMessage(errorCode);

const getValidateEmailUpdateMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.NOT_FOUND ? VALIDATE_EMAIL_UPDATE.NOT_FOUND : getCommonMessage(errorCode);

const getInviteUserMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.CONFLICT ? INVITE_USER.CONFLICT : getCommonMessage(errorCode);

const getResetPasswordMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.NOT_FOUND ? RESET_PASSWORD.NOT_FOUND : getCommonMessage(errorCode);

const getBumpFilterMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.CONFLICT ? BUMP_FILTER.CONFLICT : getCommonMessage(errorCode);

const getBoompMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.TOO_MANY_REQUESTS ? BOOMP.TOO_MANY_REQUESTS : getCommonMessage(errorCode);

const getSubmitOfferMessage = (errorCode: number) =>
    errorCode === STATUS_CODE.CONFLICT ? SUBMIT_OFFER.CONFLICT : getCommonMessage(errorCode);

const getUiMessage = ({ errorCode, name }: Props) => {
    switch (name) {
        case NAME.LOGIN:
            return getLoginMessage(errorCode);
        case NAME.VERIFY_USER:
            return VERIFY_USER.FAILED;
        case NAME.CREATE_USER:
            return getCreateUserMessage(errorCode);
        case NAME.RESEND_INVITE:
            return getResendInviteMessage(errorCode);
        case NAME.VALIDATE_EMAIL_UPDATE:
            return getValidateEmailUpdateMessage(errorCode);
        case NAME.INVITE_USER:
            return getInviteUserMessage(errorCode);
        case NAME.RESET_PASSWORD:
            return getResetPasswordMessage(errorCode);
        case NAME.BUMP_FILTER:
            return getBumpFilterMessage(errorCode);
        case NAME.BOOMP:
            return getBoompMessage(errorCode);
        case NAME.BOOST:
            return BOOST.FAILED;
        case NAME.SUBMIT_OFFER:
            return getSubmitOfferMessage(errorCode);
        default:
            return getCommonMessage(errorCode);
    }
};

export default getUiMessage;
