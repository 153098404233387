import React from 'react';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import { timeout } from 'promise-timeout';

import { GlobalContextProps } from 'state/types';

import useIsClient from 'core/hooks/useIsClient';

import ManageScroll from './ManageScroll';
import GlobalContextComponent from '../context/GlobalContext';

import Authenticated from './Authenticated';
import PageViewComponent from './PageViewComponent';
import Layout from './Layout';
import LoggedOutLayout from './Layout/LoggedOutLayout';
import SentryFallbackComponent from './SentryFallbackComponent';
import ImpersonationHandler from './ImpersonationHandler';

/**
 * Remove promiseMinDelay.js and delay.js after razzle babels node_modules
 * We can then use https://github.com/sindresorhus/p-min-delay again
 * */
import pMinDelay from '../core/promiseMinDelay';
import SessionChangedReloadBanner from './SessionChangedReloadBanner';

const MIN_DELAY = typeof window !== 'undefined' ? 200 : 0;
const MAX_DELAY = 2000;

const loadBoundaries = mod => timeout(pMinDelay(mod, MIN_DELAY), MAX_DELAY);

const Reset = loadable(() => loadBoundaries(import('../routes/reset')));
const Login = loadable(() => loadBoundaries(import('../routes/login')));
const Signup = loadable(() => loadBoundaries(import('../routes/iua/signup')));
const CompleteInviteSignup = loadable(() => loadBoundaries(import('../routes/iua/invite')));
const VerifyUser = loadable(() => loadBoundaries(import('../routes/iua/verify')));
const ResetPassword = loadable(() => loadBoundaries(import('../routes/iua/forgotPassword')));
const NewPassword = loadable(() => loadBoundaries(import('../routes/iua/newPassword')));
const UpdateEmail = loadable(() => loadBoundaries(import('../routes/iua/updateEmail')));

const SuperAdmin = loadable(() => loadBoundaries(import('features/superadmin')));

const sentryFallback = <SentryFallbackComponent />;

const onSentryError = (error, errorInfo) => {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
};

interface Props {
    context: GlobalContextProps;
}

interface AuthenticatedProps {
    user: any;
}

interface ChildrenProps {
    children: React.ReactNode;
}

const HelmetAndGlobalContext = ({ context, children }: Props & ChildrenProps) => (
    <>
        <Helmet titleTemplate="%s | Blocket Fordon administration" />
        <GlobalContextComponent context={context}>{children}</GlobalContextComponent>
    </>
);

const renderNotAuthenticatedBasedOnPath = location => {
    if (!location || !location.pathname) {
        return null;
    }

    if (location.pathname.includes('/impersonate/dealer')) {
        return <ImpersonationHandler />;
    }

    // Should really move this into <Routes> below as a proper Route
    if (location.pathname.includes('/users/invite/')) {
        return <CompleteInviteSignup code={location.pathname.replace('/users/invite/', '')} />;
    }

    return (
        <LoggedOutLayout>
            <div className="main-container">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route path="/password/reset" element={<ResetPassword />} />
                    <Route path="/password/:token" element={<NewPassword />} />
                    <Route path="/user/update-email/:token" element={<UpdateEmail />} />
                    <Route path="/users/verify/:token" element={<VerifyUser />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </div>
        </LoggedOutLayout>
    );
};

const MainLayoutWrapper = ({ context, children }: Props & ChildrenProps) => {
    const location = useLocation();

    return (
        <Sentry.ErrorBoundary fallback={sentryFallback} onError={onSentryError}>
            <Helmet titleTemplate="%s | Blocket Fordon administration" />
            <GlobalContextComponent context={context}>
                <Layout>
                    {children}

                    <PageViewComponent />
                    <ManageScroll location={location} />
                </Layout>
            </GlobalContextComponent>
        </Sentry.ErrorBoundary>
    );
};

/**
 * Let's use this when the soft launch is over and users are forced to signup with IUA
 * to use blocket admin
 */
const UserIsAuthedByAuthApiForceIUASignup = ({ context }: Props) => {
    const isClient = useIsClient();

    return isClient ? (
        <HelmetAndGlobalContext context={context}>
            <LoggedOutLayout>
                <div className="main-container">
                    <Routes>
                        <Route path="*" element={<Signup />} />
                        <Route path="/users/verify/:token" element={<VerifyUser />} />
                        <Route path="/users/invite/:loggedInCode" element={<CompleteInviteSignup />} />
                    </Routes>
                </div>
            </LoggedOutLayout>
        </HelmetAndGlobalContext>
    ) : null;
};

const HasIUALoggedInSession = ({ context }: Props) => {
    const isClient = useIsClient();
    const location = useLocation();

    if (location.pathname.includes('/impersonate/dealer')) {
        return <ImpersonationHandler />;
    }

    return (
        <Sentry.ErrorBoundary fallback={sentryFallback} onError={onSentryError}>
            {isClient && (
                <HelmetAndGlobalContext context={context}>
                    <>
                        <SessionChangedReloadBanner />
                        <Layout>
                            <Authenticated />

                            <PageViewComponent />
                            <ManageScroll location={location} />
                        </Layout>
                    </>
                </HelmetAndGlobalContext>
            )}
        </Sentry.ErrorBoundary>
    );
};

const IsAuthApiSuperUser = ({ context }: Props) => (
    <Sentry.ErrorBoundary fallback={sentryFallback} onError={onSentryError}>
        <HelmetAndGlobalContext context={context}>
            <Layout>
                <SuperAdmin />
            </Layout>
        </HelmetAndGlobalContext>
    </Sentry.ErrorBoundary>
);

const NoActiveSession = ({ context }: Props) => {
    const isClient = useIsClient();
    const location = useLocation();

    return isClient ? (
        <HelmetAndGlobalContext context={context}>{renderNotAuthenticatedBasedOnPath(location)}</HelmetAndGlobalContext>
    ) : null;
};

const MainLayout = ({ context, user }: Props & AuthenticatedProps) => {
    const isClient = useIsClient();

    const renderContent = () => {
        if (typeof user !== 'undefined' && user.level > 1) {
            return <SuperAdmin />;
        }

        return <Authenticated />;
    };

    return isClient ? <MainLayoutWrapper context={context}>{renderContent()}</MainLayoutWrapper> : null;
};

export { MainLayout, HasIUALoggedInSession, UserIsAuthedByAuthApiForceIUASignup, IsAuthApiSuperUser, NoActiveSession };
