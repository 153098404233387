import Cookie from 'js-cookie';
import universalFetch from 'core/universalFetch';
import fixClearCookies from 'core/fixClearCookies';
import createApolloClient from 'core/createApolloClient';

import { DEALER_BY_ID, DEALER_BY_CODE } from 'queries/dealer-api';

import * as USER from './actionTypes';

import { CALL_API } from '../../middleware/api';

import { actions as dealerActions } from '../dealer';
import { actions as iuaUserActions } from '../iua';

/**
 * Set active dealer for the user
 * @param {number} dealerId - id matching the selected dealer
 */
export const setDealer = dealerId => async dispatch => {
    await dispatch({ type: USER.SET_DEALER, dealerId });
};

/**
 * Set active dealer for the user
 * @param {number} dealerId - id matching the selected dealer
 */
export const changeDealer = dealerId => async (dispatch, getState) => {
    if (!dealerId) {
        return;
    }

    try {
        await dispatch(dealerActions.setDealerApiLoading({ loading: true }));

        const apolloClient = createApolloClient();

        const { data } = await apolloClient.query({
            variables: {
                id: parseInt(dealerId, 10)
            },
            query: DEALER_BY_ID
        });

        // Only do the IUA call if the user is an IUA user
        const reduxState = getState();
        if (reduxState && reduxState.iua && reduxState.iua.authenticated) {
            dispatch(iuaUserActions.changeIUAMainDealer(dealerId.toString()));
        }

        await dispatch(dealerActions.setDealerApiData({ data: data.dealerById }));
        await dispatch({ type: USER.SET_DEALER, dealerId });
    } catch (e) {
        console.error(e);
        await dispatch(dealerActions.setDealerApiError({ error: true }));
    }
};

/**
 * Call dealer-api with the dealer code to get back the dealer ir
 */
const initDealer = userState => async dispatch => {
    const dealerCode = userState.login;

    if (typeof dealerCode !== 'undefined') {
        Cookie.remove('dealer');
    }

    try {
        const apolloClient = createApolloClient();

        const {
            data: { dealerByDealerCode }
        } = await apolloClient.query({
            variables: {
                dealerCode
            },
            query: DEALER_BY_CODE
        });

        await dispatch(dealerActions.setDealerApiData({ data: dealerByDealerCode }));

        return true;
    } catch (e) {
        throw e;
    }
};

/**
 * Set user object
 */
export const setUserObject = payload => ({
    type: USER.SET_USER_OBJECT,
    payload
});

export const unauthenticated = () => dispatch => {
    dispatch({ type: USER.SET_UNAUTHENTICATED });
};

/**
 * Set user as authenticated on initialization
 * @param {Object} userObject
 * @param {number} userObject.level
 * @param {string} dealerCode
 */
export const initAuth = userObject => async dispatch => {
    dispatch(setUserObject(userObject));

    dispatch({ type: USER.SET_AUTHENTICATED });
};

/**
 * Authenticate a user using its credentials
 * @param {object} user
 * @param {string} user.username
 * @param {string} user.password
 * @param {bool} user.rememberme
 */
export const authenticate = userObject => async dispatch => {
    const fetch = universalFetch(window.fetch, {
        baseUrl: `${window.location.origin}`
    });

    const response = await dispatch({
        [CALL_API]: {
            types: [USER.USER_LOGIN_REQUEST, USER.USER_LOGIN_RESPONSE, USER.USER_LOGIN_ERROR],
            payload: { userObject },
            method: 'post',
            endpoint: '/user/auth',
            publicResource: true
        }
    });

    if (response.error && response.error === '401') {
        throw new Error('Invalid credentials');
    }

    const setAuthenticated = () => {
        dispatch({ type: USER.SET_AUTHENTICATED });
    };

    if (!response.error && response.response) {
        const { access_token: accessToken, refresh_token: refreshToken } = response.response.user;

        await fetch('/tokens', {
            method: 'POST',
            body: JSON.stringify({
                accessToken,
                refreshToken
            })
        });

        const userState = response.response.user;

        await dispatch(setUserObject(userState));

        if (userState.level < 3) {
            const dealerRequest = await dispatch(initDealer(userState));
            if (dealerRequest.error && dealerRequest.error === '401') {
                dispatch({ type: USER.USER_LOGIN_ERROR });
            } else {
                setAuthenticated();
            }
        } else {
            setAuthenticated();
        }
    }

    return response;
};

/**
 * Logout user
 */
export const deauthenticate = () => async dispatch => {
    const fetch = universalFetch(window.fetch, {
        baseUrl: `${window.location.origin}`
    });

    await dispatch({
        [CALL_API]: {
            types: [USER.USER_LOGOUT_REQUEST, USER.USER_LOGOUT_RESPONSE, USER.USER_LOGOUT_ERROR],
            payload: {},
            method: 'delete',
            endpoint: '/user/auth',
            publicResource: true
        }
    });

    await fetch('/tokens', {
        method: 'DELETE'
    });

    fixClearCookies();

    Cookie.remove('dealer', {
        domain: window.App.config.cookieDomain
    });
};
