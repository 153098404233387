import React from 'react';
import { string, object } from 'yup';
import { withFormik, FormikValues, FormikProps } from 'formik';
import { regex } from '@bytbil/utils';
import { ApolloError } from '@apollo/client';
import DialogModal from 'components/Modals/DialogModal';
import InfoModal from 'components/Modals/InfoModal';
import CustomerServiceForm from './Form';

interface Props {
    isOpen: boolean;
    toggleModal(): void;
    error: ApolloError | undefined;
    responseStatus: boolean;
    toggleModal(): void;
    helpdeskClick(): void;
}

const CustomerServiceModal = (props: FormikProps<FormikValues> & Props & EnhancedProps) => {
    const {
        isOpen,
        error,
        errors,
        values,
        touched,
        isSubmitting,
        responseStatus,
        hasAdminPro,
        modal,
        toggleModal,
        handleSubmit,
        handleBlur,
        handleChange,
        helpdeskClick,
        setFieldValue
    } = props;

    const pricePackagesBody = hasAdminPro
        ? 'Hej! Jag vill bli kontaktad angående att inaktivera Admin Pro'
        : 'Hej! Jag vill bli kontaktad angående att komma igång med Admin Pro';

    const pricePackagesBodyText = modal.origin === 'PricePackages' && pricePackagesBody;

    if (responseStatus) {
        const modalBody = (
            <div className="customer-service__modal--success">
                <p>
                    <strong>Tack för ert meddelande!</strong>
                    <br />
                    Vår Kundservice kontaktar dig snarast.
                </p>
            </div>
        );

        return (
            <InfoModal
                isOpen={isOpen}
                toggle={e => {
                    e.preventDefault();
                    toggleModal();
                }}
                header="Kontakta kundservice"
                body={modalBody}
            />
        );
    }
    if (!responseStatus) {
        const modalBody = (
            <div className="row">
                <div className="col-12 col-lg-5">
                    <p>
                        Vid frågor eller problem är du välkommen att kontakta vår kundservice eller besöka vår{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://blocket.zendesk.com/hc/sv"
                            onClick={helpdeskClick}
                        >
                            kundservice-sida <i className="fa fa-external-link" />
                        </a>
                        <br />
                        <strong>
                            Öppettider:
                            <br />
                            Vardagar kl. 08:00-17:00.
                            <br />
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <i className="fa fa-phone mr-2" />
                            <a className="text-dark" href="tel:+46774450000">
                                0774 - 45 00 00
                            </a>
                        </strong>
                    </p>
                </div>
                <div className="col-12 col-lg-7">
                    <CustomerServiceForm
                        error={error}
                        handleSubmit={handleSubmit}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        values={values}
                        touched={touched}
                        bodyText={pricePackagesBodyText || ''}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>
        );

        return (
            <DialogModal
                isOpen={isOpen}
                toggle={toggleModal}
                size="lg"
                header="Kontakta kundservice"
                body={modalBody}
                onAbort={toggleModal}
                onSubmit={handleSubmit}
                isLoading={isSubmitting}
                submitButtonText="Skicka meddelande"
            />
        );
    }

    return null;
};

interface Modal {
    origin: string;
}
interface EnhancedProps {
    hasAdminPro: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    dealerCode: string;
    // eslint-disable-next-line react/no-unused-prop-types
    dealerName: string;
    modal: Modal;
    handleSubmit(values: FormikValues): void;
}

const EnhancedCustomerServiceModal = withFormik<EnhancedProps & Props, FormikValues>({
    validationSchema: object().shape({
        name: string().required('Ange företagsnamn'),
        email: string()
            .email('E-postadressen ser inte ut att stämma')
            .test({
                message: 'Ange er e-post',
                test(value) {
                    const { phone } = this.parent; // eslint-disable-line react/no-this-in-sfc
                    if (!phone) return value != null;
                    return true;
                }
            }),
        phone: string()
            .matches(regex.phoneNumber, 'Telefonnumret ser inte ut att stämma')
            .test({
                message: 'Ange ett telefonnummer',
                test(value) {
                    const { email } = this.parent; // eslint-disable-line react/no-this-in-sfc
                    if (!email) return value != null;
                    return true;
                }
            }),
        message: string().required('Fyll i en beskrivning av ert ärende')
    }),

    mapPropsToValues: props => ({
        username: props.dealerCode || '',
        name: props.dealerName || '',
        email: '',
        phone: '',
        message: ''
    }),

    handleSubmit: async (values, formikBag) => {
        const { props } = formikBag;
        const { handleSubmit } = props;
        await handleSubmit(values);
    },

    displayName: 'CustomerService' // helps with React DevTools
})(CustomerServiceModal);

export default EnhancedCustomerServiceModal;
